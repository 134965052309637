import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby-plugin-intl"
import "./header.sass"
import classNames from "classnames"
import { defaultIntl, EventBus } from "../utils"
import Language from "./language"
import Country from "./country"
import { globalContext } from "./context"

const changeBanner = (e, data) => {
  e.preventDefault()
  EventBus.dispatch({ type: "ChangeBanner", data })
}

const Header = ({ intl = defaultIntl, page }) => {
  const { country } = useContext(globalContext)
  const [invert, setInvert] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const pagePart = page.split("-")[0] || ""
  let unsubscribe
  useEffect(() => {
    if (page === "about" || page === "") {
      unsubscribe = EventBus.subscribe(e => {
        const state = EventBus.getState()
        setInvert(state.invert)
      })
    } else {
      setInvert(true)
    }
    return () => {
      unsubscribe && unsubscribe()
    }
  }, [])
  return (
    <div className={classNames("navbar is-fixed-top", { invert })}>
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a
              role="button"
              className={classNames("navbar-burger burger", { "is-active": showMenu })}
              aria-label="menu"
              aria-expanded="false"
              data-target="main-menu"
              onClick={() => setShowMenu(!showMenu)}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
            <Link to={`/?country=${country}`} className="navbar-item logo">
              <img src={require("../images/fantuan_logo.png")} />
            </Link>
          </div>
          <div className={classNames("navbar-menu animated fast", { "is-active": showMenu, flipInX: showMenu, invert })} id="main-menu">
            {/*<Link to="/" className={classNames("navbar-item", { "is-active": true })}>*/}
            {/*  {intl.formatMessage({ id: "global.logo_alt" })}*/}
            {/*</Link>*/}
            <div className="navbar-item">
              <Link to={`/?country=${country}`} className={classNames("text-container", { "is-bold": pagePart === "about" || pagePart === "" })}>
                {intl.formatMessage({ id: "global.menu.about" })}
                {(pagePart === "about" || pagePart === "") && <div className="color-bar" />}
              </Link>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="/delivery/">
                  {intl.formatMessage({ id: "global.menu.submenu.fantuan" })}
                </a>
              </div>
            </div>
            <div className="navbar-item">
              <Link to={`/news/?country=${country}`} className={classNames("text-container", { "is-bold": pagePart === "news" })}>
                {intl.formatMessage({ id: "global.menu.newsroom" })}
                {pagePart === "news" && <div className="color-bar" />}
              </Link>
            </div>
            <div className="navbar-item">
              <Link to={`/jobs/?country=${country}`} className={classNames("text-container", { "is-bold": ["jobs", "job"].includes(pagePart) })}>
                {intl.formatMessage({ id: "global.menu.careers" })}
                {["jobs", "job"].includes(pagePart) && <div className="color-bar" />}
              </Link>
            </div>
          </div>
        </nav>
        <div className="navbar-end">
          <Language />
          <Country />
        </div>
      </div>
    </div>
  )
}
export default Header
